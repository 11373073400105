<template>
  <div
    class="form-builder__input relative"
    :class="schema.styleClasses || ''"
  >
    <q-input
      :model-value="schema.value"
      :standout="!!schema.standout"
      :outlined="schema.outlined"
      :stack-label="schema.stackLabel"
      class="full-width"
      :disable="schema.disabled"
      :readonly="schema.readonly"
      :required="schema.required"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :placeholder="schema.placeholder"
      :name="schema.inputName"
      :hide-bottom-space="!!schema.hint"
      :hint="schema.hint"
      @update:model-value="search"
      @focus="handleFocus"
      @blur="handleFocusOut"
    />

    <q-scroll-area
      v-if="isFocused && schema.value"
      style="position: absolute; top: 50px; left: 0; right: 0; z-index: 2;"
      :style="results.length <= 0 ? 'height: 50px;' : 'height: 150px;'"
      class="bg-white"
    >
      <div v-if="results.length <= 0" class="text-caption text-center">
        {{ $t('No results') }}
      </div>

      <q-list
        bordered
        separator
      >
        <q-item
          v-for="result in results"
          :key="result.place_id"
          v-ripple
          clickable
          @click="set(result)"
        >
          {{ result.label }}
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>

<script>
import { GoogleProvider } from 'leaflet-geosearch'

export default {
  name: 'AddressInput',
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      geoSearchOptions: {
        provider: new GoogleProvider({
          params: {
            key: this.schema.googleApiKey
          }
        })
      },
      inputs: {
        street_number: 'long_name',
        route: 'long_name',
        country: 'long_name',
        administrative_area_level_1: 'long_name',
        administrative_area_level_2: 'long_name',
        locality: 'long_name',
        postal_code: 'short_name',
        neighborhood: 'long_name'
      },
      results: [],
      isFocused: false
    }
  },
  methods: {
    handleFocus (e) {
      this.isFocused = true

      if (this.schema.onFocus) {
        this.schema.onFocus(e)
      }
    },
    handleFocusOut (e) {
      setTimeout(() => {
        this.isFocused = false
      }, 700)

      if (this.schema.onFocus) {
        this.schema.onFocusOut(e)
      }
    },
    set (res) {
      this.value = res.label
      this.results = []

      const place = res.raw
      const data = (place.address_components || []).reduce((acc, component) => {
        const input = component.types[0]

        if (this.inputs[input]) {
          acc[input] = component[this.inputs[input]]
        }

        return acc
      }, {})

      if (this.schema.onPlaceChanged) {
        this.schema.onPlaceChanged(res, data, place, this.model, this.schema)
      }
    },
    search (value) {
      typeof this.schema.onInput === 'function' && this.schema.onInput(value)

      if (!value) {
        return
      }

      if (!this.schema.googleApiKey) {
        return
      }

      this.geoSearchOptions.provider
        .search({ query: value })
        .then(results => {
          this.results = results
        })
    }
  }
}
</script>
